<template>
  <div>
    <PageTitle :title="'Sessions'"></PageTitle>

    <div v-if="ongoingSessions.length" class="border-bottom mb-3">
      <div class="small fw-bold mb-2">
        You have {{ ongoingSessions.length }}
        running session{{ ongoingSessions.length > 1 ? 's' : '' }}!
      </div>
      <div
        @click="viewSession(session)"
        v-for="(session, sessionIndex) in ongoingSessions"
        :key="`key-${sessionIndex}`"
        :class="sessionIndex > 0 ? 'mt-3' : ''"
        class="alert alert-danger d-flex align-items-center"
        role="button"
      >
        <div class="flex-grow-1 me-3">{{ session | sessionNameReadable }}</div>
        <SessionTimer
          :startTime="session.startedAt"
          class="badge rounded-pill bg-danger"
        ></SessionTimer>
        <fa-icon :icon="['fas', 'chevron-right']" class="ms-3"></fa-icon>
      </div>
    </div>

    <SwitchButton
      @activate="activateMode($event)"
      :actions="modeOptions"
      :activeIndex="modeActiveIndex"
      class="border-bottom pb-3 w-100"
    ></SwitchButton>

    <AthleteUpcomingFeed v-if="mode === 'upcoming'"></AthleteUpcomingFeed>

    <AthletePastFeed v-if="mode === 'past'"></AthletePastFeed>
  </div>
</template>

<script>
import { httpGet } from '@/core/http';

export default {
  name: 'AthleteFeed',
  components: {
    PageTitle: () => import('@/components/PageTitle'),
    SessionTimer: () => import('@/components/SessionTimer'),
    SwitchButton: () => import('@/components/button/SwitchButton'),
    AthleteUpcomingFeed: () => import('@/components/feed/AthleteUpcomingFeed'),
    AthletePastFeed: () => import('@/components/feed/AthletePastFeed'),
  },
  computed: {
    modeOptions() {
      return this.modes.map((m) => m.label);
    },
    modeActiveIndex() {
      return this.modes.findIndex((m) => m.value === this.mode);
    },
  },
  methods: {
    async fetchOngoingSessions() {
      try {
        const res = await httpGet('/feed/ongoing');
        this.ongoingSessions = res.data;
      } catch (err) {
        this.$store.dispatch('addSystemError', err, { root: true });
      }
    },
    viewSession(session) {
      const { sessionId } = session;
      this.$router.push(`/workout/${sessionId}`);
    },
    activateMode(modeIndex) {
      if (modeIndex === 0) {
        this.mode = 'upcoming';
      } else if (modeIndex === 1) {
        this.mode = 'past';
      }
    },
  },
  data() {
    return {
      ongoingSessions: [],
      modes: [
        {
          label: 'Upcoming',
          value: 'upcoming',
        },
        {
          label: 'Past sessions',
          value: 'past',
        },
      ],
      mode: 'upcoming',
    };
  },
  mounted() {
    this.fetchOngoingSessions();
  },
};
</script>
